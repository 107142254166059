<template>
  <v-app>
    <v-card
      class="mx-auto my-auto"
      :loading="loading"
      elevation="5"
      min-width="375"
    >
      <v-card-text>
        <v-img
          :src="logoUrl"
          height="auto"
          width="250"
          class="mx-auto mt-10"
        ></v-img>
      </v-card-text>
      <v-card-title class="justify-center"
        >Welcome. Please log in below.</v-card-title
      >
      <v-divider class="mx-4 mb-4"></v-divider>
      <template v-if="challengeExp">
        <p class="warningMsg">Login time has expired. Please try again.</p>
      </template>
      <v-form
        ref="form"
        v-model="valid"
        class="mr-4 ml-4"
        @keyup.native.enter="login"
      >
        <v-card-text>
          <v-text-field
            v-model="org"
            :rules="orgnameRules"
            color="#00677A"
            label="Account"
            ref="org"
            required
            v-if="showOrg"
            :placeholder="!orgAutofilled ? ' ' : ''"
          ></v-text-field>
          <v-text-field
            v-model="username"
            :rules="usernameRules"
            color="#00677A"
            label="User Name"
            ref="username"
            id="username"
            :class="{ 'input-group--dirty': dirtyUsername }"
            :placeholder="!usernameAutofilled ? ' ' : ''"
            required
            type="username"
          ></v-text-field>
          <v-text-field
            v-model="password"
            :rules="passwordRules"
            label="Password"
            :class="{ 'input-group--dirty': dirtyPassword }"
            color="#00677A"
            required
            id="password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            :placeholder="!passwordAutofilled ? ' ' : ''"
          >
          </v-text-field>
        </v-card-text>
        <a class="justify-end fpLink" href="forgotpassword.php"
          >Forgot Password</a
        >
        <v-card-actions>
          <v-btn
            :disabled="!valid"
            color="success"
            outlined
            class="mr-1"
            @click="login"
          >
            Log in
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <div>Please contact your system administrator for help.</div>
        </v-card-text>
      </v-form>
    </v-card>
    <v-snackbar v-model="snackbar" :timeout="timeout"
      >{{ snackbartext }}
      <v-btn color="blue" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    timeout: 2000,
    snackbartext: null,
    snackbar: false,
    showOrg: true,
    logoUrl: null,
    login_username: null,
    login_org: null,
    login_password: null,
    challenge: null,
    org: null,
    username: null,
    password: null,
    salt: null,
    showPassword: false,
    valid: true,
    loading: false,
    orgnameRules: [
      (v) => !!v || "Organization is required",
      (v) => (v && v.length > 2) || "Organization must be greater than 2",
    ],
    usernameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length > 2) || "Name must be greater than 2 characters",
    ],
    passwordRules: [(v) => !!v || "Password is required"],
    rebranded: null,
    abbr: null,
    dirtyUsername: null,
    dirtyPassword: null,
    autofillContent: `"${String.fromCharCode(0xfeff)}"`,
    challengeExp: false,
  }),
  mounted() {
    this.challenge = document
      .querySelector("#challenge")
      .getAttribute("data-challenge");

    let sha1Script = document.createElement("script");
    sha1Script.setAttribute("src", "/javascripts/sha1.js");
    document.head.appendChild(sha1Script);

    let md5Script = document.createElement("script");
    md5Script.setAttribute("src", "/javascripts/md5.js");
    document.head.appendChild(md5Script);

    this.rebranded = document
      .querySelector("#rebranded")
      .getAttribute("data-challenge");
    this.abbr = document.querySelector("#abbr").getAttribute("data-challenge");
    this.logoUrl = document
      .querySelector("#logoUrl")
      .getAttribute("data-challenge");

    if (this.abbr === null || this.abbr === undefined || this.abbr === "") {
      this.$refs.org.focus();
      this.showOrg = true;
    } else {
      this.org = this.abbr;
      this.showOrg = false;
      this.$refs.username.focus();
    }

    let times = 0;
    const interval = setInterval(() => {
      times += 1;
      if ((this.dirtyUsername && this.dirtyPassword) || times === 20) {
        clearInterval(interval);
      }
      this.dirtyUsername =
        document.querySelector("[id='username:-internal-autofill-selected']") !=
        null;
      //console.log("queryselector username",document.querySelector('#username:-internal-autofill-selected'))

      if (this.dirtyUsername != null) {
        //document.querySelector('#username:-internal-autofill-selected').classList.add('v-label--active')
        document
          .querySelector("label[for=username]")
          .classList.add("v-label--active");
        this.usernameAutofilled = true;
      }

      this.dirtyPassword =
        document.querySelector("[id='password:-internal-autofill-selected']") !=
        null;
      //console.log("queryselector password",document.querySelector('#password:-internal-autofill-selected'))
      if (this.dirtyPassword != null) {
        //document.querySelector('#password:-internal-autofill-selected').classList.add('v-label--active')
        document
          .querySelector("label[for=password]")
          .classList.add("v-label--active");
        this.passwordAutofilled = true;
      }
    }, 100);
  },
  watch: {
    org() {
      this.orgAutofilled = true;
    },
    username() {
      this.usernameAutofilled = true;
    },
    password() {
      this.passwordAutofilled = true;
    },
  },
  methods: {
    goLink(link) {
      window.location = link;
    },
    login() {
      if (this.$refs.form.validate()) {
        axios
          .get("/ajax/getJsSalt.php", {
            params: { username: this.username, org: this.org },
          })
          .then((response) => {
            if (
              response.data.ajax_response.response.userinfo.username ==
              this.username
            ) {
              if (response.data.ajax_response.response.userinfo.salt) {
                //using external script
                // eslint-disable-next-line
                this.login_password = hex_hmac_md5(
                  // eslint-disable-next-line
                  hex_sha1(this.password),
                  this.challenge +
                    response.data.ajax_response.response.userinfo.salt
                );
                axios
                  .get("/ajax/verifyLoginJs.php", {
                    params: {
                      username: this.username,
                      password: this.login_password,
                      org: this.org,
                    },
                  })
                  .then((response) => {
                    if (response.data.ajax_response.response.app.length > 0) {
                      if (
                        response.data.ajax_response.response.app[0] !=
                        "session expire"
                      ) {
                        sessionStorage.clear();
                        //valid login
                        if (
                          response.data.ajax_response.response.app.length == 1
                        ) {
                          //console.log("valid login - single app")
                          window.location =
                            "/admin/" +
                            response.data.ajax_response.response.app +
                            "/";
                        } else {
                          //console.log("valid login - multiple apps")
                          window.location =
                            "/admin/" +
                            response.data.ajax_response.response.app +
                            "/";
                        }
                      } else {
                        this.challengeExp = true;
                        setTimeout(() => window.location.reload(), 3000);
                      }
                    } else {
                      //invalid login
                      this.snackbartext = "Invalid credentials specified.";
                      this.snackbar = true;
                    }
                  })
                  .catch(function(error) {
                    console.log(error);
                  });
              }
            }
          });
      }
    },
  },
};
</script>
<style>
@-webkit-keyframes autofill {
  0%,
  100% {
    color: inherit;
    background: transparent;
  }
}
input:-webkit-autofill {
  content: "\feff";
}

.warningMsg {
  padding: 10px 15px;
  background: #e53b30;
  color: #fff;
}

.fpLink {
  font-size: 14px;
  color: #e53b30;
  display: flex;
  cursor: pointer;
  padding-right: 16px;
}
</style>
